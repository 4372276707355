import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {version} from '../../meta.json'

class Footer extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <ul className="dropdown-menu userinfo arrow">
                <li><Link to="/manager/profile">
                    <i className="ti ti-user"/><span>Profile</span>
                    <span className="badge badge-info pull-right">{version}</span>
                </Link>
                </li>
                <li><a href="https://www.venuemonk.com/forgot"><i className="ti ti-user"/><span>Change Password</span></a></li>
                <li className="divider"/>
                <li><a onClick={() => this.props.logOut()}><i className="ti ti-shift-right"/><span>Sign Out</span></a></li>
            </ul>
        );
    }
}

export default Footer;
