import React from 'react';
import {ThreeBounce} from 'better-react-spinkit';

export function BasicLoader() {
  return (
    <div className="text-center">
      <ThreeBounce color={'#491369'} size={15} />
    </div>
  );
}

export default BasicLoader;
