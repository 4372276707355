import React, { Component } from 'react';

import { Link } from 'react-router-dom';

export class NormalLink extends Component {

  render() {
    let isActive = this.props.activeUrl === this.props.url;
    let linkClass = isActive ? 'active' : '';
      return (
          <li className={linkClass}>
              <Link to={this.props.url}>
                  <i className={this.props.icon ? this.props.icon : ''} />
                  <span>{this.props.name}</span>
              </Link>
          </li>
      );
  }
}

export default NormalLink;

