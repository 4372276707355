'use strict';

const _ = require('lodash');

function splitName(name) {
	var model = {
		fname: "",
		lname: "",
	};
	var fullName = name.split(" ", 3);
	if (fullName && fullName.length > 0) {
		fullName.forEach(function (item, i) {
			if (i === 0) {
				model.fname = sentenceCase(item);
			} else {
				model.lname += sentenceCase(item) + " ";
			}
		});
	}
	model.lname = model.lname.trim();
	return model;
}

function sentenceCase(name) {
	return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
}

function groupAllData(data) {
    let temp = [];
    for (let a in data) {
        temp = temp.concat(data[a]);
    }
    return temp;
}

function removeDuplicateElements(events) {
    let newEvents = [];
    for (let i = 0; i < events.length; i++) {
        let flag = 0;
        if (events[i]) {
            for (let j = i + 1; j < events.length; j++) {
                if (events[i] === events[j]) {
                    flag = 1;
                    break;
                }
            }
            if (flag === 0) {
                newEvents.push(events[i]);
            }
        }
    }
    return newEvents;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function randomIntFromInterval(min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
}

function getUserInfo(user) {
    return {
        _id: user._id,
        username: user.local.name,
        email: user.local.email,
        phone: user.phone,
        access_level: user.access_level,
        user_type: user.user_type,
        venue_list: user.venue_list
    };
}

function isValidIndianMobile (phone) {
    let newPhone = _.toString(phone);
    return newPhone && newPhone.length === 10 && (newPhone[0] === '9' || newPhone[0] === '8' || newPhone[0] === '7' || newPhone[0] === '6');
}

// sorts in descending order
function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    });
}

function hideMobileNumber (phone) {
    if (phone) {
        let strings = _.toString(phone).split('');
        if (strings && strings.length > 4) {
            strings = _.map(strings, function (item, index) {
                if (index === 2 || index === 3 || index === 5 || index === 7 || index === 8) {
                    return 'X';
                } else {
                    return item;
                }
            });
            return strings.join('');
        } else {
            return '';
        }
    } else {
        return '';
    }
}

function hideHalfChars (string , odd) {
    if (string && string !== '') {
        let strings = _.toString(string).split('');
        if (odd) {
            strings = _.map(strings, function (item, index) {
                if (index%2 !== 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        } else {
            strings = _.map(strings, function (item, index) {
                if (index%2 === 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        }
        return strings.join('');
    } else {
        return string;
    }
}

function hideEmailAddress (email) {
    if (email) {
        let strings = _.toString(email).toLowerCase().split('.');
        let ext = strings.pop();
        strings = _.toString(strings.join('.')).split('@');
        if (strings && strings.length > 1) {
            let firstPart = hideHalfChars(strings[0], 1);
            let secondPart = hideHalfChars(strings[1], 0);
            return firstPart + "@" + secondPart + "." + ext;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

function bool(str) {
    if (str === void 0) return false;
    return str.toLowerCase() === 'true';
}

module.exports = {
	splitName: splitName,
	sentenceCase: sentenceCase,
    removeDuplicateElements:removeDuplicateElements,
    groupAllData: groupAllData,
    capitalizeFirstLetter: capitalizeFirstLetter,
    randomIntFromInterval: randomIntFromInterval,
    getUserInfo: getUserInfo,
    isValidIndianMobile: isValidIndianMobile,
    sortByKey: sortByKey,
    hideMobileNumber: hideMobileNumber,
    hideEmailAddress: hideEmailAddress,
    bool: bool
};
