import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class BreadCrumb extends Component{

    render() {
        if (this.props.breadcrumbs && this.props.breadcrumbs.length > 0) {
            return(
                <ol className="breadcrumb">
                    {this.props.breadcrumbs.map(item => (
                        <li
                            key={item.link}
                            className={item.isActive ? 'active' : ''}
                        >
                            <Link to={item.link}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ol>
            );
        } else {
            return null;
        }
    }
}

export default BreadCrumb;

