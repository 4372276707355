import React from 'react';

export default function AccessControl(props) {

    const hasPermission = () => {
        if (props.shouldHavePermissions && props.shouldHavePermissions.length > 0) {
            if (props.userPermissions) {
                let userPermissions = props.userPermissions;
                let shouldHavePermissions = props.shouldHavePermissions;
                let leftPermissions = shouldHavePermissions.filter(item => userPermissions.indexOf(item) !== -1);
                return leftPermissions && leftPermissions.length > 0;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    if(hasPermission()) {
        return props.children;
    } else {
        return(
            <div></div>
        );
    }
}
