export default [
    {
        isNormalLink: false,
        name: 'Business Development',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["bd-role:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/business-development/leads',
                name: 'Partner Leads',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/partner-app/users/list',
                name: 'Partner App Users',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/mypartner-approval/list',
                name: 'My Partner Approval',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/business-development/lead-analytics',
                name: 'Lead Analytics',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/business-development/venue-partners',
                name: 'Venue Partners List',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/business-development/package-cost',
                name: 'Package Cost List',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/venue/algo-test',
                name: 'Algo Graph',
                icon: '',
                shouldHavePermissions: ["algograph:read"]
            }
        ]
    },
    
    {
        isNormalLink: false,
        name: 'Services',
        icon: 'ti ti-control-shuffle',
        shouldHavePermissions: ["services:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/services/alertsystem/list',
                name: 'Alerts List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/corporate/offers',
                name: 'Offers',
                icon: '',
                shouldHavePermissions: ["bd-role:read"],
            },
            {
                isNormalLink: true,
                url: '/manager/services/city/list',
                name: 'City List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/content/list',
                name: 'Content List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/event-templates/list',
                name: 'Event Template List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/email/templates',
                name: 'Email Templates List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/email/tasks',
                name: 'Email Tasks List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/smstoolkit/events',
                name: 'Events Sms List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/keywords/list',
                name: 'Keywords List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/leadform/list',
                name: 'Lead Forms List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/paid-partner/list',
                name: 'Paid Partner List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/redirect/list',
                name: 'Redirect List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/scripts/file-upload',
                name: 'Script Uploads',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/short-url/list',
                name: 'Short Url List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/sms/tasks',
                name: 'Sms Tasks List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/sms/templates',
                name: 'Sms Templates List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/tasktypes/list',
                name: 'Task Codes List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/whatsapp/templates',
                name: 'WhatsApp Templates List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/whatsapp/tasks',
                name: 'WhatsApp Tasks List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/summary/templates',
                name: 'Summary Templates List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/pdf/templates',
                name: 'Pdf Templates List',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
        ]
    },
    
    {
        isNormalLink: false,
        name: 'Subscriptions',
        icon: 'ti ti-control-shuffle',
        shouldHavePermissions: ["services:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/services/subscriptions/create',
                name: 'Subscription Create',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/subscriptions/list',
                name: 'List of Subscriptions',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/plans/list',
                name: 'List of Plans',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/payments/list',
                name: 'List of Payments',
                icon: '',
                shouldHavePermissions: ["services:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/services/cheques/list',
                name: 'List of Cheques',
                icon: '',
                shouldHavePermissions: ["services:read"]
            }
        ]
    },
] 