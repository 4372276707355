import React, {Component} from 'react';
import {} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';

class NotFound extends Component {

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to={this.props.location.state._refurl ? this.props.location.state._refurl : '/'}/>
        } else {
            return (
                <div className="container" style={{marginTop: 50}}>
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h1>404 Page Not Found</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            );
        }
    }
}

export default NotFound;

