import React, {Component} from 'react';

class Footer extends Component{
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return(
            <div className="clearfix">
                <ul className="list-unstyled list-inline pull-left">
                    <li><h6 style={{margin: 0}}>©  PurplePatch Online Services Pvt Ltd</h6></li>
                </ul>
                <button className="pull-right btn btn-link btn-xs hidden-print" id="back-to-top"><i className="ti ti-arrow-up" /></button>
            </div>
        );
    }
}

export default Footer;
